<template>
  <el-dialog
    title="新增"
    :visible.sync="visible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="visible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="80px"
    >
      <el-form-item
        label="所属资源"
        prop="resource_id"
        :rules="[ { required: true, message: '所属资源不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.resource_id"
          class="mr10"
          placeholder="请选择资源"
        >
          <el-option
            v-for="(item,index) in resources"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
          label="提交方式"
      >
        <el-select
                v-model="form.method"
                class="mr10"
                placeholder="请选择资源"
        >
          <el-option
                  v-for="(item,index) in ['POST','GET','DELETE','PUT','PATCH']"
                  :key="index"
                  :label="item"
                  :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item
          label="接口路径"
      >
        <el-input
            v-model="form.path"
            type="text"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import { error, success } from '@core/utils/utils'
import {CreateResourcePerm, ListResource} from "@/api/system/menu/menu";

export default {
  name: 'DeptSave',
  data() {
    return {
      visible: false,
      loading: false,
      resources: [],
      form: {
        resource_id: '',
        path: '',
        method: '',
      },
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        resource_id: '',
        path: '',
        method: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          CreateResourcePerm(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$emit('handleFilter', this.form.resource_id)
              this.visible = false
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    dialogOpen() {
      this.getParentResourceOptions()
    },
    getParentResourceOptions() {
      ListResource().then(res => {
        if (res.data.code === 0) {
          this.resources = res.data.data
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
