<template>
    <b-card>
        <!-- <div
                style="cursor: pointer;margin-bottom: 20px"
                @click="show = !show"
        >
            <el-link
                    type="primary"
                    :underline="false"
                    class="mr10"
                    style="font-size: 16px"
            > 
                {{title}}
            </el-link>
            <i :class="show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"  />
        </div> -->
        <b-card-text>
            <span v-show="show" class="page-desc">{{desc}}</span>
            <el-tooltip v-show="tips" effect="light" placement="top">
                <div slot="content" style="whiteSpace: pre-line">
                {{tips}}
                </div>
                <!-- <i class="el-icon-info" style="font-size: 14px;margin-left: 4px"/> -->
            </el-tooltip>
        </b-card-text>
    </b-card>
</template>

<script>
  export default {
    name: 'PageIntroduce',
    props: {
      title:{
        type:String
      },
      desc:{
        type:String
      },
      tips:{
        type:String
      }
    },
    data(){
      return {
        show: true
      }
    }
  }
</script>

<style scoped>
.card-body{
  padding: 0.5rem 1.25rem;
}
</style>
