<template>
  <el-dialog
    :title="form.id ? '修改' : '新增'"
    :visible.sync="visible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="visible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="120px"
    >
      <el-form-item
        label="菜单名称"
        prop="name"
        :rules="[ { required: true, message: '菜单名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
        <el-form-item
            prop="type"
            :rules="[ { required: true, message: '菜单类型不能为空', trigger: 'change' }]"
                label="菜单类型"
        >
          <el-select
                  v-model="form.type"
                  placeholder="请选择类型"
          >
            <el-option
                    v-for="(item,index) in menuTypes"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
            />
          </el-select>
        </el-form-item>
      <el-form-item
          label="父级菜单"
      >
        <el-select
            v-model="form.parentId"
            placeholder="请选择父级菜单"
        >
          <el-option
              v-for="(item,index) in resourceOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
        <el-form-item
                label="菜单ICON"
        >
            <el-input
                    v-model="form.icon"
                    type="text"
            />
        </el-form-item>
      <el-form-item
          label="组件名"
      >
        <el-input
            v-model="form.component"
            type="text"
        />
      </el-form-item>
      <el-form-item
          label="Path类型"
      >
        <el-select
            v-model="form.pathType"
        >
          <el-option
              v-for="(item,index) in [{label: '组件路由',value: 1},{label: '直接URL',value: 2}]"
              :key="index"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
          label="Path"
      >
        <el-input
            v-model="form.path"
            type="text"
        />
      </el-form-item>
      <el-form-item
          label="序号"
      >
        <el-input
            v-model="form.sortNo"
            type="number"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { error, success } from '@core/utils/utils'
import { QueryMuneResource, saveOrUpdateResource } from '@/api/system/menu/menu'

export default {
  name: 'DeptSave',
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        name: '',
        icon: '',
        sortNo: '',
        pathType: undefined,
        path: '',
        component: '',
        type: 0,
        parentId: '',
        id: '',
      },
      resourceOptions: [],
      menuTypes: [{ label: '目录', value: 0 }, { label: '导航栏菜单', value: 1 }, { label: '左侧菜单', value: 2 }, { label: '按钮', value: 5 }],
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        name: '',
        sortNo: '',
        icon: '',
        type: '',
        parentId: '',
        id: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          if (this.form.id === '') {
            delete this.form.id
          }
          this.form.sortNo = Number(this.form.sortNo)
          saveOrUpdateResource(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$emit('handleFilter')
              this.visible = false
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    dialogOpen() {
      this.getParentResourceOptions()
    },
    getParentResourceOptions() {
      QueryMuneResource().then(res => {
        if (res.data.code === 0) {
          this.resourceOptions = res.data.data
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
