<template>
  <div>
    <b-card>
      <b-row class="match-height">
        <b-col
          xl="8"
          md="8"
        >
          <div class="flex-between mb10">
            <h4>菜单列表</h4>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="saveDialogShow(1)"
            >
              新增一级菜单
            </el-button>
          </div>
          <el-table
            v-loading="loading"
            :max-height="pageH"
            :data="resourceTreeData"
            row-key="id"
            border
            :highlight-current-row="true"
            :tree-props="{children: 'children'}"
            @row-click="rowClick"
          >
            <el-table-column
              prop="name"
              label="菜单名称"
              :align="'left'"
            />
            <el-table-column
              prop="type"
              label="菜单类型"
            >
              <template slot-scope="{row}">
                {{ row.type===0?'目录' : row.type===1?'导航栏菜单':row.type===2?'左侧菜单':row.type===5?'按钮':'其他'
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="component"
              label="组件名称"
            />
            <el-table-column
              prop="pathType"
              label="PATH类型"
            >
              <template slot-scope="{row}">
                {{ row.pathType===1?'组件路由':'直接URL' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="path"
              label="PATH"
            />
            <el-table-column
              prop="sortNo"
              label="菜单序号"
            />
            <el-table-column
              label="操作"
              width="160px"
            >
              <template slot-scope="{row}">
                <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="saveDialogShow(2,row)"
                >
                  <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-edit" />
                  </el-tooltip>
                </el-link>
                <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="saveDialogShow(3,row)"
                >
                  <el-tooltip
                    content="添加子菜单"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-circle-plus-outline" />
                  </el-tooltip>
                </el-link>
                <el-link
                  class="mr10"
                  type="primary"
                  @click="del(row)"
                >
                  <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </b-col>
        <b-col
          xl="4"
          md="4"
        >
          <div class="flex-between mb10">
            <h4>权限接口列表</h4>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="addPermDialogShow"
            >
              添加权限接口
            </el-button>
          </div>
          <el-table
            v-loading="listPermLoading"
            :max-height="pageH - 80"
            border
            :data="resourcePermList"
          >
            <el-table-column
              prop="path"
              label="地址"
            />
            <el-table-column
              prop="method"
              label="方式"
            />

            <el-table-column
              label="操作"
              width="80px"
            >
              <template slot-scope="{row}">
                <el-link
                  :underline="false"
                  type="primary"
                  @click="delResourcePerm(row)"
                >
                  <el-tooltip
                    content="移除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>

        </b-col>
      </b-row>
      <ResourceSave
        ref="resourceSave"
        @handleFilter="getList"
      />
      <ResourceAddPerm
        ref="resourceAddPerm"
        @handleFilter="queryPermByResource"
      />
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions,import/no-cycle
import {
  DeleteResource, DeleteResourcePerm,
  ListResource, ListResourcePerm,
} from '@/api/system/menu/menu'

import {
  error, success, treeDataTranslate,
} from '@core/utils/utils'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import ResourceSave from '@/views/settings/menu/MenuSave.vue'
import ResourceAddPerm from '@/views/settings/menu/MenuAddPerm.vue'

export default {
  components: {
    ResourceSave,
    ResourceAddPerm,
    PageIntroduce,
  },

  data() {
    return {
      pageH: document.body.clientHeight - 250,
      loading: false,
      listPermLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
      resourceTreeData: [],
      resourcePermList: [],
      activeResourceId: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    rowCls(row) {
      if (row.row.id === this.activeResourceId) {
        return 'current-row-active'
      }
    },
    delResourcePerm(row) {
      this.$confirm('是否确认移除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        const ids = []
        ids.push(row.id)
        DeleteResourcePerm({ ids }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.queryPermByResource(this.activeResourceId)
          } else {
            error(resData.msg)
          }
        })
      })
    },
    rowClick(row) {
      this.activeResourceId = row.id
      this.queryPermByResource(row.id)
    },
    queryPermByResource(resourceId) {
      this.listPermLoading = true
      ListResourcePerm(resourceId).then(res => {
        this.listPermLoading = false
        const resData = res.data
        if (resData.code === 0) {
          this.resourcePermList = resData.data
        }
      })
    },
    del(row) {
      if (row.children !== undefined) {
        error('该菜单存在子菜单，无法删除')
      } else {
        this.$confirm('是否确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          DeleteResource(row.id).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.resourcePermList = []
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
        })
      }
    },
    saveDialogShow(type, row) {
      this.$refs.resourceSave.visible = true
      // 添加一级菜单
      if (type === 1) {
        this.$refs.resourceSave.form.name = ''
        this.$refs.resourceSave.form.parentId = 0
        this.$refs.resourceSave.form.id = ''
        this.$refs.resourceSave.form.icon = ''
      } else if (type === 2) { // 编辑菜单
        this.$refs.resourceSave.form = { ...row }
      } else if (type === 3) { // 添加子菜单
        this.$refs.resourceSave.form.parentId = row.id
      }
    },
    addPermDialogShow() {
      this.$refs.resourceAddPerm.visible = true
      this.$refs.resourceAddPerm.form.resourceId = this.activeResourceId
    },
    getList() {
      this.loading = true
      ListResource(this.listQuery).then(res => {
        this.loading = false
        const resources = res.data.data
        if (resources.length > 0) {
          this.resourceTreeData = treeDataTranslate(resources, 'id', 'parentId')
          if (!this.activeResourceId) {
            this.activeResourceId = resources[0].id
          }
          this.queryPermByResource(this.activeResourceId)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
<style lang="scss">
   .el-table--border .el-table__cell:first-child .cell {
        text-align: left !important;
    }
</style>
