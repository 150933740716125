var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"8","md":"8"}},[_c('div',{staticClass:"flex-between mb10"},[_c('h4',[_vm._v("菜单列表")]),_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.saveDialogShow(1)}}},[_vm._v(" 新增一级菜单 ")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"max-height":_vm.pageH,"data":_vm.resourceTreeData,"row-key":"id","border":"","highlight-current-row":true,"tree-props":{children: 'children'}},on:{"row-click":_vm.rowClick}},[_c('el-table-column',{attrs:{"prop":"name","label":"菜单名称","align":'left'}}),_c('el-table-column',{attrs:{"prop":"type","label":"菜单类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.type===0?'目录' : row.type===1?'导航栏菜单':row.type===2?'左侧菜单':row.type===5?'按钮':'其他')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"component","label":"组件名称"}}),_c('el-table-column',{attrs:{"prop":"pathType","label":"PATH类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.pathType===1?'组件路由':'直接URL')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"path","label":"PATH"}}),_c('el-table-column',{attrs:{"prop":"sortNo","label":"菜单序号"}}),_c('el-table-column',{attrs:{"label":"操作","width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{staticClass:"mr10",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.saveDialogShow(2,row)}}},[_c('el-tooltip',{attrs:{"content":"修改","placement":"top","effect":"light"}},[_c('i',{staticClass:"el-icon-edit"})])],1),_c('el-link',{staticClass:"mr10",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.saveDialogShow(3,row)}}},[_c('el-tooltip',{attrs:{"content":"添加子菜单","placement":"top","effect":"light"}},[_c('i',{staticClass:"el-icon-circle-plus-outline"})])],1),_c('el-link',{staticClass:"mr10",attrs:{"type":"primary"},on:{"click":function($event){return _vm.del(row)}}},[_c('el-tooltip',{attrs:{"content":"删除","placement":"top","effect":"light"}},[_c('i',{staticClass:"el-icon-delete"})])],1)]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","md":"4"}},[_c('div',{staticClass:"flex-between mb10"},[_c('h4',[_vm._v("权限接口列表")]),_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":_vm.addPermDialogShow}},[_vm._v(" 添加权限接口 ")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listPermLoading),expression:"listPermLoading"}],attrs:{"max-height":_vm.pageH - 80,"border":"","data":_vm.resourcePermList}},[_c('el-table-column',{attrs:{"prop":"path","label":"地址"}}),_c('el-table-column',{attrs:{"prop":"method","label":"方式"}}),_c('el-table-column',{attrs:{"label":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.delResourcePerm(row)}}},[_c('el-tooltip',{attrs:{"content":"移除","placement":"top","effect":"light"}},[_c('i',{staticClass:"el-icon-delete"})])],1)]}}])})],1)],1)],1),_c('ResourceSave',{ref:"resourceSave",on:{"handleFilter":_vm.getList}}),_c('ResourceAddPerm',{ref:"resourceAddPerm",on:{"handleFilter":_vm.queryPermByResource}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }