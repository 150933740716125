import { get, post } from '@/libs/axios'

// 查询资源
export const QueryMuneResource = () => get('/sys/resource/menu_list')

export const ListResource = () => get('/sys/menu/nav')

// 添加资源
export const CreateResource = params => post('/sys/app/CreateResource', params)
// 删除资源
export const DeleteResource = id => get(`/sys/resource/delete?id=${id}`)

// 查询资源接口
export const ListResourcePerm = id => get(`/sys/resource/list_perm?resourceId=${id}`)
// 添加资源
export const CreateResourcePerm = params => post('/sys/resource/create_perm', params)
// 更新资源
export const UpdateResourcePerm = params => post('/sys/resource/update_perm', params)
// 删除资源
export const DeleteResourcePerm = params => post('/sys/resource/delete_perm', params)

export const saveOrUpdateResource = params => {
  const submitUrl = params.id ? '/sys/resource/update' : '/sys/resource/create'
  return post(submitUrl, params)
}
